// import images
import aboutUsBanner2x from '../images/banner/aboutUsBanner-2x.jpg';
import aboutUsBanner from '../images/banner/aboutUsBanner.jpg';
import airlineUzb from '../images/airline-uzb.png';
import airlineUzb2x from '../images/airline-uzb-2x.png';
import airlineRedWings from '../images/airline-redwings.png';
import airlineRedWings2x from '../images/airline-redwings-2x.png';
import airlineAlis from '../images/airline-alis.png';
import airlineAlis2x from '../images/airline-alis-2x.png';
import airlineAeroflot from '../images/airline-aeroflot.png';
import airlineAeroflot2x from '../images/airline-aeroflot-2x.png';
import logoAngie from '../images/AG_logo_wword.png';
import logoAngie2x from '../images/AG_logo_wword-2x.png';
import logoAzerpost from '../images/logo_azerpost.png';
import logoAzerpost2x from '../images/logo_azerpost-2x.png';
import logoUzbPost from '../images/logo_uzb_post.png';
import logoUzbPost2x from '../images/logo_uzb_post-2x.png';
import logoAir from '../images/logo_mswair.png';
import logoAir2x from '../images/logo_mswair-2x.png';
import logoExpress from '../images/logo_express.png';
import logoExpress2x from '../images/logo_express-2x.png';

// import css
import '../theme/default/agel/modules/modal_box.scss';
import '../theme/default/agel/modules/buttons.scss';
import '../theme/default/agel/base.scss';
import '../theme/default/agel/about-us.scss';

// import js
import React from "react";
import Base from "../components/base";
import {Link, useI18next} from "gatsby-plugin-react-i18next";
import {graphql} from "gatsby";
import ExternalUrl from "../components/external_url";

interface AirlineServiceData {
    text?: string,
    images?: ImageData[],
    label: string,
}

interface ImageData {
    src1x: string,
    src2x: string,
    className: string,
    url?: string,
}

const airlines: AirlineServiceData[] = [{
    images: [{src1x: airlineUzb, src2x: airlineUzb2x, className: 'uzb'}],
    label: 'airline:routes.hy'
}, {
    images: [{src1x: airlineRedWings, src2x: airlineRedWings2x, className: 'red-wings'}],
    label: 'airline:routes.wz'
}, {
    images: [{src1x: airlineAlis, src2x: airlineAlis2x, className: 'alis'}],
    label: 'airline:routes.cp'
}, {
    images: [{src1x: airlineAeroflot, src2x: airlineAeroflot2x, className: 'aeroflot'}],
    label: 'airline:routes.su'
}];

const services: AirlineServiceData[] = [{
    images: [{src1x: logoAngie, src2x: logoAngie2x, className: 'angie'}],
    label: 'index:productServiceSection.services.angie'
}, {
    images: [
        {
            src1x: logoAzerpost, src2x: logoAzerpost2x, className: 'azerpost'
        }, {
            src1x: logoUzbPost,
            src2x: logoUzbPost2x,
            className: 'uzbpost'
        }],
    label: 'index:productServiceSection.services.azerpost'
}, {
    images: [{src1x: logoAir, src2x: logoAir2x, className: 'air', url: 'https://www.mswair.com/'}],
    label: 'index:productServiceSection.services.air'
}, {
    images: [{src1x: logoExpress, src2x: logoExpress2x, className: 'express', url: 'https://ship.msw.express/'}],
    label: 'index:productServiceSection.services.express'
}, {
    text: 'Angie Tower',
    label: 'index:productServiceSection.services.tower'
}];


const AboutUs = () => {
    const i18n = useI18next(['index', 'translation']);
    const {t} = i18n;

    return (
        <Base title={t('translation:nav.aboutUs')} t={t} i18n={i18n}>
            <main className={'about-us'}>
                <div className={'main-banner'}>
                    <img src={aboutUsBanner} srcSet={aboutUsBanner2x + ' 2x'}/>
                    <div className={'banner-desc'}>
                        <h1>{t('index:banner.title')}</h1>
                        <p>{t('index:banner.desc')}</p>
                    </div>
                </div>
                <article className={'intro'}>
                    <h1>{t('index:introduction.title')}</h1>
                    <p dangerouslySetInnerHTML={{__html: t('index:introduction.content')}}/>
                </article>
                <section className={"airlines"}>
                    <h1>{t('airline:title')}</h1>
                    <ul>
                        {airlines.map((a, i) => <li key={i}>
                            {a.images && (a.images.length > 1 ?
                                <div className={'images'}>{a.images.map((i, idx) => <img key={idx} src={i.src1x}
                                                                                         srcSet={i.src2x + ' 2x'}
                                                                                         className={i.className}/>)}</div> :
                                <img src={a.images[0].src1x} srcSet={a.images[0].src2x + ' 2x'}
                                     className={a.images[0].className}/>)}
                            <p dangerouslySetInnerHTML={{__html: t(a.label)}}/>
                        </li>)}
                    </ul>
                    <Link to={'/airline'} className={'primary button'}>{t('index:button.more')}</Link>
                </section>
                <section className={"services"}>
                    <h1>{t('index:productServiceSection.title')}</h1>
                    <ul>
                        {services.map((s, i) => <li key={i}>
                            {s.text && <h3>{s.text}</h3>}
                            {s.images && (s.images.length > 1 ?
                                <div className={'images'}>{s.images.map((i, idx) => {
                                    let img = <img key={idx} src={i.src1x}
                                                   srcSet={i.src2x + ' 2x'}
                                                   className={i.className}/>;

                                    if (i.url) img = <ExternalUrl href={i.url} rel={'external'}>{img}</ExternalUrl>;
                                    return img
                                })}</div> : (s.images[0].url ?
                                    <ExternalUrl href={s.images[0].url} rel={'external'}>
                                        <img src={s.images[0].src1x}
                                             srcSet={s.images[0].src2x + ' 2x'}
                                             className={s.images[0].className}/>
                                    </ExternalUrl> :
                                    <img src={s.images[0].src1x} srcSet={s.images[0].src2x + ' 2x'}
                                         className={s.images[0].className}/>))}
                            <p dangerouslySetInnerHTML={{__html: t(s.label)}}/>
                        </li>)}
                    </ul>
                    <Link to={'/services'} className={'primary button'}>{t('index:button.more')}</Link>
                </section>
            </main>
        </Base>
    )
}

export default AboutUs;

export const data = graphql`
    query($language: String!) {
        locales: allLocale(filter: {ns: {in: ["translation", "airline", "index"]}, language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;